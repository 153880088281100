﻿namespace('HelpNotesForms').HelpNotesFormsClass = function HelpNotesFormsClass() {

    /************** SINGLETON *****************/
    // Asegurar instancia única
    if (arguments.callee._singletonInstance) {
        return arguments.callee._singletonInstance;
    }

    arguments.callee._singletonInstance = this;

    makePublisher(this);

    //Funcion que inicializa las ayudas de los formularios
    //capaid:capa de contenido ddonde se encuentran las ayudas, generalmente cCn
    //elemento: tipos de elemnto a los que se le aplicaran las ayudas
    this.LoadHelpNotesForms = function (dataObject) {
        if (!DOM1 && !DOM2) { return null; }

        // Lo pongo aqui a fuego pq esta a fuego por n sitios, para eso es mejor que este centralizado
        if (typeof (dataObject.LayerId == "undefined"))
            dataObject.LayerId = 'cCn';

        if (typeof (dataObject.Tag == "undefined"))
            dataObject.Tag = 'a';

        var layerMainDocument = document.getElementsByClassName("MainDocument")[0];

        if (layerMainDocument != null) {
            var arr = layerMainDocument.getElementsByTagName(dataObject.Tag);
        }
        else {
        //Lo utilizamos para mostrar un texto cuando pasas el raton por encima de una imagen.
            var layerElement = document.getElementById(dataObject.LayerId);
            if (layerElement == null) return;

            var arr = layerElement.getElementsByTagName(dataObject.Tag);
        }



        PrepareTooltip();


        var txt;
        var layer;
        var capa;
        

        for (var idarr = 0; idarr < arr.length; idarr++) {
            if (arr[idarr].className == "nh" || arr[idarr].className == "nh sto") {
                txt = "";
                layer = arr[idarr].nextSibling.tagName == "CITE" ? arr[idarr].nextSibling : arr[idarr].nextElementSibling;
                if (layer.tagName == "CITE")
                    txt = layer.innerHTML; //obtenemos el texto                    
                arr[idarr].id = "hlp_" + capa; //le damos un id al anchor
                if (txt != "")
                    CreateTooltip(arr[idarr], txt);
            }
        }
    };

    this.RegisterSubscribeEventLoadHelpNotesForms = function () {
            
    };

    return arguments.callee._singletonInstance;
};

/************** METODOS 'ESTATICO' *****************/
// Metodo para conseguir instancia unica
namespace('HelpNotesForms').HelpNotesFormsClass.getInstance = function () {
    return namespace('HelpNotesForms').HelpNotesFormsClass();
}

//Funcion que inicializa las ayudas de los formularios
//capaid:capa de contenido ddonde se encuentran las ayudas, generalmente cCn
//elemento: tipos de elemnto a los que se le aplicaran las ayudas
function HelpNotesForm(layerId, tag) {
    var obj = new Object();
    obj.LayerId = layerId;
    obj.Tag = tag;
    namespace('HelpNotesForms').HelpNotesFormsClass.getInstance().LoadHelpNotesForms(obj);
}

//funcion para crear el tooltip
//el:elemnto al que se le agregara el tooltip
//txt:mensaje del tooltip
function CreateTooltip(el, txt) {
    var tooltip, b, s, l;
    if (txt == null || txt.length == 0) txt = "link:";
    tooltip = CreateEl("span", "helpFormtooltip");
    s = CreateEl("span", "top");
    s.innerHTML = txt;
    tooltip.appendChild(s);
    b = CreateEl("b", "bottom");
    tooltip.appendChild(b);
    setOpacity(tooltip);
    el.tooltip = tooltip;
    el.onmouseover = includeTooltip;
    el.onmouseout = removeTooltip;
    el.onmousemove = Locate;
}

jQuery(document).ready(function () {
    namespace('HelpNotesForms').HelpNotesFormsClass.getInstance().RegisterSubscribeEventLoadHelpNotesForms();
});

function HelpNotes() {
    var nh = jQuery("a[class^=nh]");
    nh.each(element => {
        if (an.parentElement.getElementsByTagName("input") > 0){
            element.parentElement.getElementsByTagName("input").item(0).placeholder =
             element.parentElement.getElementsByTagName("cite").item(0).innerText; 
        }
    });
}


//js de formularios
function showProcessingMessage(messageWaitScreen) {

    jQuery.blockUI.defaults.message = messageWaitScreen;

    jQuery(document).ready(function () {
        jQuery.blockUI({
            css: {
            border: 'none',
            padding: '15px',
            backgroundColor: '#000',
            '-webkit-border-radius': '10px',
            '-moz-border-radius': '10px',
            opacity: .5,
            color: '#fff',
            font: '20px Trebuchet MS'
        }
        });
    });
}

function loadformsprovence(ind) {
    try {
        var clientid = 'sprovence';
        document.getElementById('scityhiden').value = '';
        document.getElementById('sprovencehiden').value = '';
        document.getElementById('scityhiden').value = document.getElementById('scity').options[document.getElementById('scity').selectedIndex].value;
        var res = Wke.Presentation.WebControls.FormControl.LoadValuesForProvence(ind);
        document.getElementById(clientid).options.length = 0;
        for (ires = 0; ires < res.value.length; ires++) {
            document.getElementById(clientid).options[ires] = new Option(res.value[ires], res.value[ires]);
        }
    }
    catch (e) {
        alert('error in loadformsprovence');
    }
}

function loadformsmatter(ind) {
    try {
        //var clientid = 'smatter';
        document.getElementById('smatterhiden').value = '';
        //document.getElementById('sprovencehiden').value = '';
        document.getElementById('smatterhiden').value = document.getElementById('smatter').options[document.getElementById('smatter').selectedIndex].value;
        //        var res = Wke.Presentation.WebControls.FormControl.LoadValuesForProvence(ind);
        //        document.getElementById(clientid).options.length = 0;
        //        for (ires = 0; ires < res.value.length; ires++) {
        //            document.getElementById(clientid).options[ires] = new Option(res.value[ires], res.value[ires]);
        //        }
    }
    catch (e) {
        alert('error in loadformsmatter');
    }
}


function loadformsvatres(ind) {
    try {

        document.getElementById('svotre').value = '';

        document.getElementById('svotre').value = document.getElementById('svotres').options[document.getElementById('svotres').selectedIndex].value;

    }
    catch (e) {
        alert('error in loadformsvatres');
    }
}

function loadformsambitos(ind) {
    try {

        document.getElementById('sambito').value = '';

        document.getElementById('sambito').value = document.getElementById('sambitos').options[document.getElementById('sambitos').selectedIndex].value;

    }
    catch (e) {
        alert('error in loadformsambito');
    }
}

function loadformscheckmatter(id) {
    try {
        document.getElementById('scheckmatterhiden').value = '';
        //hiddenmatter.value = '';
        //document.getElementById('sprovencehiden').value = '';
        var checks = document.getElementById(id).getElementsByTagName('INPUT');
        for (i = 0; i < checks.length; i++) {
            if (checks[i].checked) {
                if (document.getElementById('scheckmatterhiden').value != '') {
                    document.getElementById('scheckmatterhiden').value += '|' + checks[i].name + '-' + checks[i].value;
                }
                else {
                    document.getElementById('scheckmatterhiden').value = checks[i].name + '-' + checks[i].value;
                }
            }
        }
    }
    catch (e) {
        alert('error in loadformsmatter');
    }
}

//Funcion que carga el valor de los input hidden en lo checks de la politica de privacidad
function loadChecksPrivacyPolicy() {
    document.getElementById('chkPrivacyPolicyWK').checked = jQuery("#inputPrivacyPolicyWK").attr("value") == "true" ? true : false;
    document.getElementById('chkPrivacyPolicyThirds').checked = jQuery("#inputPrivacyPolicyTE").attr("value") == "true" ? true : false;
    document.getElementById('chkPrivacyPolicyDistributors').checked = jQuery("#inputPrivacyPolicyD").attr("value") == "true" ? true : false;
}

//Funcion que guarda el valor de los checks de la politica de privacidad del popup en los input hidden
function saveChecksPrivacyPolicy() {
    jQuery("#inputPrivacyPolicyWK").val(document.getElementById('chkPrivacyPolicyWK').checked);
    jQuery("#inputPrivacyPolicyTE").val(document.getElementById('chkPrivacyPolicyThirds').checked);
    jQuery("#inputPrivacyPolicyD").val(document.getElementById('chkPrivacyPolicyDistributors').checked);
}

function loadformscheckmatterWS(id) {
    try {
        document.getElementById('scheckmatterhiden').value = '';
        //hiddenmatter.value = '';
        //document.getElementById('sprovencehiden').value = '';
        var checks = document.getElementById(id).getElementsByTagName('INPUT');
        for (i = 0; i < checks.length; i++) {
            if (checks[i].checked) {
                if (document.getElementById('scheckmatterhiden').value != '') {
                    document.getElementById('scheckmatterhiden').value += '|' + checks[i].name + '-' + checks[i].value;
                }
                else {
                    document.getElementById('scheckmatterhiden').value = checks[i].name + '-' + checks[i].value;
                }
            }
        }
    }
    catch (e) {
        alert('error in loadformsmatter');
    }
}

function loadformscheckinterestareas(id) {
    try {
        document.getElementById('scheckinterestareashiden').value = '';

        var checks = document.getElementById(id).getElementsByTagName('INPUT');
        for (i = 0; i < checks.length; i++) {
            if (checks[i].checked) {
                if (document.getElementById('scheckinterestareashiden').value != '') {
                    document.getElementById('scheckinterestareashiden').value += '|' + checks[i].name;
                }
                else {
                    document.getElementById('scheckinterestareashiden').value = checks[i].name;
                }
            }
        }
    }
    catch (e) {
        alert('error in loadformsinterestareas');
    }
}

var formsControl_selectedModules_arr = new Array();
// Funcion que gestiona los valores seleccionados por el campo de formulario configurado como CheksGroupOfCommercialsModules
// Parameters: E -> value codigo:descripcion del modulo
function AddOrRemoveCommercialModuleToHiddenField(value, checked) {

    if (checked) {
        // Chequear
        if (formsControl_selectedModules_arr.length == 0) {
            // Primera inserccion
            formsControl_selectedModules_arr.push(value);
        }
        else {
            if (formsControl_selectedModules_arr.indexOf(value) == -1) {
                // Comprobacion de seguridad, no debe estar en el array, puesto que estamos en el caso checked.
                formsControl_selectedModules_arr.push(value);
            }
        }
    }
    else {
        // Deschequear
        if (formsControl_selectedModules_arr.length > 0) {
            // Comprobacion de seguridad, para deschequear tiene que haber algun elemento.
            var pos = formsControl_selectedModules_arr.indexOf(value)
            if (pos != -1) {
                // Si esta el valor en el array lo quita.
                formsControl_selectedModules_arr[pos] = '';
            }
        }
    }

    // Eliminamos elementos vacios.
    var arraux = new Array();
    var i = 0;
    var j = 0;
    for (i = 0; i < formsControl_selectedModules_arr.length; i++) {
        if (formsControl_selectedModules_arr[i] != '') {
            arraux[j] = formsControl_selectedModules_arr[i];
            j++;
        }
    }
    formsControl_selectedModules_arr = arraux.valueOf();

    // Asignamos la informacion al hidden
    document.getElementById("scheckmodulehidden").value = formsControl_selectedModules_arr.toString();
}

// Muestra un boton para cerrar el popup al validar el formulario.
function AddFormPopupCloseButton(buttonText) {
    if (window.parent.jQuery('#closeFormPopup').length) {
        window.parent.jQuery('#closeFormPopup').removeClass('buttonHidden');
    }
}

function loadformsquestionnaire(obligatorio) {
    var questionnaire = document.getElementById('questionnaire');
    var allValues = document.getElementById('squestionnaire');
    allValues.value = '';
    for (var i = 1; i < questionnaire.childNodes.length - 1; i++) {
        if (obligatorio && document.getElementById('response' + i).value == '') {
            allValues.value = '';
            break;
        }
        else {
            allValues.value += document.getElementById('question' + i).innerHTML + ':' + document.getElementById('response' + i).value + '|';
        }
    }
}

jQuery(document).ready(function () {
    jQuery("body").on("change", "select", function () {
        var currentValue = this.value;
        jQuery("div").find("#" + this.id + "> option").removeAttr("selected");
        jQuery("div").find("#" + this.id + "> option[value='" + currentValue + "']").attr("selected", true);
        jQuery("div").find("#" + this.id).val(currentValue);
    });
});

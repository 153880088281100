var totalprogress = 0;
var totalask = 0;
var fieldset = 0;
var ems = 0;

function Control() {
    var dFO = $("div[id='tBody']").attr("class");
    var SmartForm = $("div[id='tBody'] div").attr("id");
    if (dFO == "FO" & SmartForm == "cPx") {
        GiveFormatToElements();
        var onclickElement = $("div[onclick*='hlQuestion']");
        onclickElement.removeAttr('onclick')
        $("a[class=nh],a[class=hd]").hover(
            function() {
                var vhref = $(this).attr("href");
                var qhref = vhref.replace("javascript:vNt('", "");
                qhref = qhref.replace("')", "");
                verNotaSF(qhref);
            }
        );
        $("#cCn span[class='logo']").attr('title', 'Mostrar/Ocultar modelo');
        $("#cCn span[class='logo']").click(
            function() {
                $("div[id='tBody']").toggleClass(" view-text ");
            }
        );
        calculateDone()
    }
}

function GiveFormatToElements() {
    emToElement();
    checkTextToRightInput();
    footNoteToRight();
    plusMinusGroup();
}

this.emToElement = function() {
    var dtxt = jQuery("#dTxT:not(.pmG)");
    var ems = dtxt.find("em[class^=isf]");
    ems.each(function(index, element) {
        var emId = element.id;
        var copyTo = jQuery("#" + emId).data("copy-to");
        // Distintivo para identificar select = "s + id"
        if ($('#s' + emId).length > 0) {
            emToSelect(emId, copyTo);
            // Distintivo para identificar input = "T + id"
        } else if ($('#T' + emId).length > 0) {
            emToInput(emId);
            /* Si copyTo no es "undefined" y no ha entrado antes significa que ya existe una copia de este campo*/
        } else if (jQuery.type(copyTo) !== "undefined") {
            var sel = typeCopyTo(copyTo, emId);
            jQuery(sel).insertAfter("#" + emId);

        }
        jQuery("#" + emId).remove();
    });
    this.resizeInputReset();
}

function emToSelect(id, copyTo) {
    var sel = $('#s' + id).parent().html();
    // Añade referencia por si hay select relacionados con este
    if (jQuery.type(copyTo) !== "undefined") {
        sel = sel.replace("name=\"s" + id, "name=\"" + copyTo);
    }
    jQuery(sel).insertAfter("#" + id);
    jQuery(sel).remove();
}

function emToInput(id) {
    var sel = $('#T' + id).parent();
    $('#T' + id)[0].placeholder = getHelpLabel(sel);
    jQuery(sel).insertAfter("#" + id);
}

this.getHelpLabel = function(element) {
    if (element.find("cite").length > 0) {
        return element.find("cite").get(0).innerText;
    }
    return "";
}

this.plusMinusGroup = function() {
    var pmg = jQuery("#dTxT div[class^=pmG]");
    var ems = pmg.find("em[class^=isf]");

    ems.each(function(index, element) {
        var emId = element.id;
        jQuery("#" + emId).remove();
    });

    pmg.each(function(index, element) {
        var pmgId = element.id;
        var plus = $('#bl' + pmgId.substring(1)).addClass($("#" + pmgId).attr('class'));
        plus.addClass('plus');
        jQuery(plus).insertAfter("#" + pmgId);
        // excepción incluimos checkbox dentro de plusminusgroup
        var pmgCCxId = pmgId.substring(1);
        var sel = $('#' + pmgCCxId);
        var selcB = sel.children('.cB');
        var selrG = sel.children('.rG');
        var selrB = sel.children('.rG').children('.rB');
        $(selcB).appendTo("#r" + pmgCCxId);
        $(selrG).appendTo("#r" + pmgCCxId);
        var seldd = selcB.children('dd').children();
        $(seldd).remove();
        var selddrG = selrG.children('dd').children();
        $(selddrG).remove();
        var selddrB = selrB.children('dd').children();
        $(selddrB).remove();
    });
}

this.checkTextToRightInput = function() {
    var cle = jQuery("#dTxT em[class=cle]");
    cle.each(function(_index, element) {
        var cleId = element.id.substring(1);
        jQuery("#Q" + cleId).hide();
        if ($('#T' + cleId).length > 0) {
            var sel = $('#T' + cleId).get(0);
            jQuery(sel).insertBefore("#r" + cleId);
        }
    });
    this.resizeInputReset();
}


this.footNoteToRight = function() {
    var footnotes = jQuery("#dNoT");
    var dtxt = jQuery("#dTxT")[0];
    footnotes.each(function(_index, element) {
        dtxt.appendChild(element)
    });

    var textLiterals = jQuery(".vnt");
    textLiterals.each(function(_index, textLiteral) {
        textLiteral.remove();
    });
}

this.typeCopyTo = function(emOrigen, emId) {
    if ($('#s' + emOrigen).length) {
        let sel = jQuery('<select class="sl" id="s' + emId + '" onchange="copyTo(\'s' + emId + '\',\'' + emOrigen + '\')" data-copy-from="' + emOrigen + '" />');
        sel.append($('#s' + emOrigen).parent().find("option").clone(true));
        return sel.insertAfter("#" + emId);
    } else if ($('#T' + emOrigen).length) {
        return jQuery('<input type="text" class="iMed" id="T' + emId + '" oninput="copyTo(\'T' + emId + '\',\'' + emOrigen + '\')" data-copy-from="' + emOrigen + '" placeholder="' + getHelpLabel($('#T' + emOrigen).parent()) + '" />').insertBefore("#" + emId);
    } else {
        return null;
    }
}

this.copyTo = function(inputId, copyTo) {
    var value = jQuery("#" + inputId).val();
    jQuery("input[data-copy-from='" + copyTo + "']").val(value);
    jQuery("select[data-copy-from='" + copyTo + "']").val(value);
    jQuery("select[data-copy-from='" + copyTo + "'] option[value='" + value + "']").attr('selected', 'selected');
}

function calculateDone() {
    var totalRadio = $("#cCx .rG").length;
    var totalRadioDisabled = $("#cCx .disabled .rG").length;
    var totalcheck = $("#cCx .cB").length;
    var totalcheckDisabled = $("#cCx .disabled .cB").length;

    var totalObligatorio = totalRadio - totalRadioDisabled;
    var totalOpcional = totalcheck - totalcheckDisabled;

    var totalRadioDone = $("#cCx .rG.done").length;
    var totalRadioDoneDisabled = $("#cCx .disabled .rG.done").length;
    var totalcheckDone = $("#cCx .cB.done").length;
    var totalcheckDoneDisabled = $("#cCx .disabled .cB.done").length;

    var totalObligatorioDone = totalRadioDone - totalRadioDoneDisabled;
    var totalOpcionalDone = totalcheckDone - totalcheckDoneDisabled;

    var totalPreguntasDone = totalRadioDone + totalcheckDone;
    var totalPreguntas = totalObligatorio + totalOpcional;
    var totalProgress = (100 * totalObligatorioDone) / totalObligatorio;

    var contestar = 'Obligatorio: ' + totalObligatorio +
        '\nObligatorio done: ' + totalObligatorioDone +
        '\nOpcionales:' + totalOpcional +
        '\nOpcionales done:' + totalOpcionalDone +
        '\nPreguntas: ' + totalPreguntas +
        '\nPreguntas done: ' + totalPreguntasDone +
        '\nProgress: ' + totalProgress + '%';

    var pContestarObligatorio = totalObligatorioDone + '/' + totalObligatorio + ' obligatorias ';
    var pContestarOpcional = totalOpcionalDone + ' /' + totalOpcional;
    var pContestar = 'Cuestiones: ';

    if (totalObligatorio === 0) {
        pContestar += pContestarOpcional + ' opcionales ';
        totalProgress = 100;
    } else if (pContestarOpcional === 0) {
        pContestar += pContestarObligatorio;
    } else if (totalObligatorio > 0) {
        pContestar += pContestarObligatorio + ' - ' + pContestarOpcional + ' opcionales ';
    }

    $("#pContestadas").html(totalProgress);
    $("#pContestar").html(pContestar);

    if (totalProgress === 100) {
        $("div[id='tBody']").addClass("smart-forms done");
    } else if ($("div[id='tBody']").hasClass("smart-forms done")) {
        $("div[id='tBody']").removeClass("smart-forms done");
    }
}

function nextSF() {}

function prevSF() {}

function finishSF() {}

function backSF() {}

function endSF() {}

function hlQuestion(id) {}

function verNotaSF(idNT) {
    $("#" + idNT).toggleClass("nCl");
    $("#" + idNT).toggleClass("nOp");
}

/* **************************************************************************** */

/* **************** mpSF(1,2,3,4,5) **************** */
//  funcion mpSF = mostrar pregunta SmartForm
//     input onclick / a onclick
/* **************** Parámetros **************** */
// elementoRadio    =>> elemento onclick
// eMarcado         =>> id elemento de texto cCx a mostrar/rellenar
// eMostrar         =>> subpregunta a mostrar (fieldset o dd)
// eTipo            =>> Tipo de elemento de onclick
// eHabilitar       =>> id input siguiente donde enfocar
/* **************** Tipos **************** */
// "A" Select       =>> input Radio generado de select
// "M" Radiobox     =>> input Radio generado de radiobox
// "C" CheckText    =>> input checkbox
// "P" Plusminus    =>> a Plusmiusgroup

function mpSF(elementoRadio, eMarcado, eMostrar, eTipo, eHabilitar) {
    var ancla = '#' + elementoRadio;
    var pregunta = '#' + eMostrar;
    var respuesta = '#' + eMarcado;
    switch (eTipo) {
        case "A":
            jQuery("select[data-copy-from='" + eMarcado + "']").val(elementoRadio.value);
            break;
        case "M":
            actionRadioButton(eMarcado, eMostrar, pregunta, respuesta);
            break;
        case "C":
            if (eMostrar !== "") {
                actionCheckBox(eMarcado, eMostrar, pregunta, respuesta)
            } else {
                actionCheckText(respuesta);
            }
            break;
        case "P":
            actionPlusMinus(eMostrar, ancla, pregunta, respuesta);
            break;
        default:
            //Nada
    }
    if (eHabilitar != "") {
        var foco = '#' + eHabilitar;
        $(foco).addClass(" foco");
        $(foco).focus();
    }
    $(".error").remove();
    calculateDone();
}

function inSF(cajaTexto, elemento) {
    this.copyTo("T" + elemento, elemento);
}

this.resizeInputReset = function() {
    jQuery("input.emToInput").each(function(index, element) {
        var inputId = element.id;
        jQuery("#" + inputId).attr('size', jQuery("#" + inputId).val().length);
    });
}

this.resizeInput = function(id) {
    var copyTo = jQuery("#" + id).data("copy-to");
    if (jQuery.type(copyTo) === "undefined") {
        jQuery("#" + id).attr('size', jQuery("#" + id).val().length);
        if (jQuery("#" + id).val().length < 1) {
            jQuery("#" + id).attr('size', '1');
        }
    } else {
        jQuery("input[data-copy-to='" + copyTo + "']").attr('size', jQuery("#" + id).val().length);
        if (jQuery("#" + id).val().length < 1) {
            jQuery("input[data-copy-to='" + copyTo + "']").attr('size', '1');
        }
    }
}

function GotoToElement(ide) {
    const element = document.getElementById(ide);
    element.scrollIntoView(true);
}

function actionRadioButton(eMarcado, eMostrar, pregunta, respuesta) {
    var newPregunta = eMostrar.substr(0, eMostrar.indexOf('_'));
    var elOcultar = $("*[id^=" + newPregunta + "_]");
    var thisFieldset = $("[id^=" + newPregunta + "]");
    $(thisFieldset).removeClass("enabled op");
    $(elOcultar).addClass("disabled cl");
    $(pregunta).removeClass("disabled cl");
    $(pregunta).addClass("enabled op");
    var txRadio = eMarcado.substr(0, eMarcado.indexOf('_'));
    var txOcultar = $("*[id^=" + txRadio + "_]");
    $(txOcultar).removeClass("op");
    $(txOcultar).removeClass("hle");
    $(txOcultar).addClass("cl");
    $(respuesta).removeClass("cl");
    $(respuesta).addClass("op");
    $(respuesta).addClass("hle");
    GotoToElement(eMarcado);
    $(thisFieldset).addClass(" done ");
}

function actionCheckBox(eMarcado, eMostrar, pregunta, respuesta) {
    if ($(pregunta).hasClass("disabled cl")) {
        $(pregunta).removeClass("disabled cl");
        $(pregunta).addClass("enabled op");
        $(respuesta).removeClass("cle");
        $(respuesta).addClass("ope");
        $(respuesta).show();
    } else if ($(pregunta).hasClass("enabled op")) {
        $(pregunta).removeClass("enabled op");
        $(pregunta).addClass("disabled cl");
        $(respuesta).removeClass("ope");
        $(respuesta).addClass("cle");
        $(respuesta).hide();
    }
    var thisFieldsetId = eMostrar.substr(0, eMostrar.indexOf('_'));
    var thisFieldset = $("[id^=" + thisFieldsetId + "]");
    $(thisFieldset).parent().toggleClass(" done ");
    GotoToElement(eMarcado);
    $(respuesta).removeClass("cl");
}

function actionCheckText(respuesta) {
    if ($(respuesta).hasClass("cle")) {
        $(respuesta).removeClass("cle");
        $(respuesta).addClass("ope");
    } else if ($(respuesta).hasClass("ope")) {
        $(respuesta).removeClass("ope");
        $(respuesta).addClass("cle");
    }
    $(respuesta).removeClass("cl");
}

function actionPlusMinus(eMostrar, ancla, pregunta, respuesta) {
    $(pregunta).toggle('slow', function() {
        if ($(pregunta).css('display') == 'block') {
            $(ancla).html("<b> - </b>").addClass('minus');
            $(ancla).removeClass('plus');
            $(pregunta).removeClass("pmG disabled cl");
            $(pregunta).addClass("pmG enabled op");
            $("#bl" + pregunta.substring(1)).removeClass("cl");
            $(respuesta).removeClass("cl");
        } else {
            $(ancla).html(" + ").addClass('plus');
            $(ancla).removeClass('minus');
            $(pregunta).removeClass("pmG enabled op");
            $(pregunta).addClass("pmG disabled cl");
            $("#bl" + pregunta.substring(1)).addClass("cl");
            $(respuesta).addClass("cl");
        }
    });
    $(respuesta).toggle('slow');
    $(respuesta).addClass("hle");
    GotoToElement(eMostrar);
}

﻿(function (jQuery) {

    jQuery.jQueryPopup = {
        // These properties can be read/written by accessing jQuery.jQueryPopup.propertyName from your scripts at any time

        okButton: 'OK',         // text for the OK button
        cancelButton: 'Cancel', // text for the Cancel button

        // Private methods
        initClass: function (popupId, popupClass) {
            // Se quitan los estilos automáticos para meterlo en un class
            var popupOffset = jQuery('#popup_container_' + popupId).parent().offset();
            jQuery('#popup_container_' + popupId).parent().removeAttr('style');
            if (popupClass) {
                jQuery('#popup_container_' + popupId).parent().addClass(popupClass);
            } else {
                jQuery('#popup_container_' + popupId).parent().addClass("popupContainerParentClass");
            }
            jQuery('#popup_container_' + popupId).parent().offset(popupOffset);
            jQuery('#popup_container_' + popupId).removeAttr('style');
            
            // Se centra el popup
            jQuery('#popup_container_' + popupId).dialog("option", "position", "center");           
        },

        // Public methods

        alert: function (message, title, callback, buttonText) {
            jQuery(jQuery.jQueryPopup.body("alert")).dialog({
                resizable: false,
                closeText: getLanguageText("alertMessage", "closeButton"),
                width: 'auto',
                height: 'auto',
                modal: true,
                title: title,
                autopen: true,
                close: function () { jQuery(this).remove(); },
                buttons: [
                    {
                        text: (typeof (buttonText) == "undefined") ? jQuery.jQueryPopup.okButton : buttonText,
                        click: function () {
                            jQuery(this).dialog("close");
                            if (callback) callback(true);
                        }
                    }
                ]
            });

            jQuery('#popup_message_alert').html(message);
            this.initClass("alert");
        },

        alertValue: function (message, title, callback, value, buttonText) {
            jQuery(jQuery.jQueryPopup.body("alertValue")).dialog({
                resizable: false,
                closeText: getLanguageText("alertMessage", "closeButton"),
                position: 'center',
                width: 'auto',
                height: 'auto',
                modal: true,
                title: title,
                autopen: true,
                close: function () { jQuery(this).remove(); },
                buttons: [
                    {
                        text: (typeof (buttonText) == "undefined") ? jQuery.jQueryPopup.okButton : buttonText,
                        click: function () {
                            jQuery(this).dialog("close");
                            if (callback) callback(value);
                        }
                    }
                ]
            });

            jQuery('#popup_message_alertValue').html(message);
            this.initClass("alertValue");
        },

        confirm: function (message, title, callback, yesButtonText, noButtonText) {
            jQuery(jQuery.jQueryPopup.body("confirm")).dialog({
                resizable: false,
                closeText: getLanguageText("alertMessage", "closeButton"),
                position: 'center',
                width: 'auto',
                height: 'auto',
                modal: true,
                title: title,
                autopen: true,
                close: function () { jQuery(this).remove(); },
                buttons: [
                    {
                        text: (typeof (yesButtonText) == "undefined") ? jQuery.jQueryPopup.okButton : yesButtonText,
                        click: function () {
                            jQuery(this).dialog("close");
                            if (callback) callback(true);
                            return true;
                        }
                    },
                    {
                        text: (typeof (noButtonText) == "undefined") ? jQuery.jQueryPopup.cancelButton : noButtonText,
                        click: function () {
                            jQuery(this).dialog("close");
                            if (callback) callback(false);
                            return false;
                        }
                    }
                ]
            });

            jQuery('#popup_message_confirm').html(message);
            this.initClass("confirm");
        },

        prompt: function (message, value, title, callback, yesButtonText, noButtonText) {
            jQuery(jQuery.jQueryPopup.body("prompt")).dialog({
                resizable: false,
                closeText: getLanguageText("alertMessage", "closeButton"),
                width: 'auto',
                height: 'auto',
                modal: true,
                title: title,
                autopen: true,
                close: function () { jQuery(this).remove(); },
                buttons: [
                    {
                        text: (typeof (yesButtonText) == "undefined") ? jQuery.jQueryPopup.okButton : yesButtonText,
                        click: function () {
                            value.text = jQuery("#popup_prompt").val();
                            jQuery(this).dialog("close");
                            if (callback)
                                      callback(value);

                        }
                    },
                    {
                        text: (typeof (noButtonText) == "undefined") ? jQuery.jQueryPopup.cancelButton : noButtonText,
                        click: function () {
                            jQuery(this).dialog("close");
                            if (callback) callback(null);
                        }
                    }
                ]
            });

            jQuery('#popup_message_prompt').html(message);
            jQuery("#popup_message_prompt").append('<br /><input type="text" size="45" id="popup_prompt" />');
            
            this.initClass("prompt");
        },

        ask: function (message, value, title, yesButtonText, noButtonText, callback) {
            jQuery(jQuery.jQueryPopup.body("ask")).dialog({
                resizable: false,
                closeText: getLanguageText("alertMessage", "closeButton"),
                position: 'center',
                width: 'auto',
                height: 'auto',
                modal: true,
                title: title,
                autopen: true,
                close: function () { jQuery(this).remove(); },
                buttons: [
                    {
                        text: (typeof (yesButtonText) == "undefined") ? jQuery.jQueryPopup.okButton : yesButtonText,
                        click: function () {
                            jQuery(this).dialog("close");
                            if (callback) {
                                value.answer = true;
                                callback(value);
                            }
                        }
                    },
                    {
                        text: (typeof (noButtonText) == "undefined") ? jQuery.jQueryPopup.cancelButton : noButtonText,
                        click: function () {
                            jQuery(this).dialog("close");
                            if (callback) {
                                value.answer = false;
                                callback(value);
                            }
                        }
                    }
                ]
            });

            jQuery('#popup_message_ask').html(message);
            this.initClass("ask");
        },

        // onLoadFunction, onCloseFunction y beforeCreateFunction se ejecutan con un eval()
        // appendTo: array de divs a los que se intenta anexar la capa del popup por orden. Si no se encuentra una capa se
        // busca la siguiente y si no a la de por defecto. (Dejar como último parámetro, porque se utilizará poco) 
        // (Lo utilizamos para que el contenido del popup coja los estilos ya definidos p.e. para documento y no duplicarlos.)
        urlPopup: function (url, popupClass, title, popupButtons, onLoadFunction, onCloseFunction, beforeCreateFunction, appendToDiv, id, isModal) {
            if (typeof (id) == 'undefined' || id == null)
                id = 'url';

            var myDialog = jQuery(jQuery.jQueryPopup.body(id));
            var appendDivId = "body";

            if (typeof popupButtons == 'undefined' || popupButtons == null || popupButtons == "") {
                popupButtons = [];
            }

            // Se recorre el array de capas a las que anexar el popup
            if (typeof appendToDiv != 'undefined' && appendToDiv != null && appendToDiv != "") {
                var divIndex = 0;
                while (!jQuery("#" + appendToDiv[divIndex]).length && divIndex < appendToDiv.length) {
                    divIndex++;
                }

                if (divIndex < appendToDiv.length) {
                    appendDivId = "#" + appendToDiv[divIndex];
                }
            }

            jQuery.ajax({
                url: url,
                type: "POST",
                dataType: "html",
                success: function (obj) {
                    if (beforeCreateFunction && beforeCreateFunction != "null" && beforeCreateFunction != "") {
                        if (!eval(beforeCreateFunction)) return;
                    }

                    myDialog.dialog({
                        appendTo: appendDivId,
                        buttons: popupButtons,
                        closeText: getLanguageText("alertMessage", "closeButton"),
                        dialogClass: popupClass,
                        draggable: true,
                        resizable: false,
                        position: 'center',
                        width: 'auto',
                        height: 'auto',
                        modal: isModal,
                        title: title, 
                        autopen: true,
                        close: function () {
                            if (onCloseFunction) {
                                eval(onCloseFunction);
                            }

                            jQuery(this).remove();
                        },
                        open: function () {

                            jQuery('#popup_container_' + id).html(obj);

                            if (onLoadFunction) {
                                eval(onLoadFunction);
                            }

                            jQuery.jQueryPopup.initClass(id, popupClass);
                        }
                     
                    });
                }
            });
        },

        // Para abrir un formulario dentro de un iframe en un popup.
        formPopup: function (url, popupClass, title) {            
            var formPopupBody = jQuery('<div id="popup_container_form" class="popupContainerClass">' +
                                    '<iframe id="formIframe" class="formPopup" ></iframe>' +
                                '</div>');
            var buttons = {};
            buttons["CloseForm"] = { text: getLanguageText("Forms", "closeButtonText"), id: "closeFormPopup", click: function () { jQuery('#popup_container_form').dialog('close'); } };
            jQuery('#popup_container').dialog('option', 'buttons', buttons);

            formPopupBody.dialog({
                buttons: buttons,
                autoOpen: true,
                closeText: getLanguageText("alertMessage", "closeButton"),
                position: 'center',
                title: title,
                height: 'auto',
                width: 'auto',
                modal: true,
                close: function () {
                    jQuery(this).remove();
                },
                open: function () {
                    jQuery('#closeFormPopup').addClass('buttonHidden');
                    jQuery(document).ready(function () {
                        jQuery('#formIframe').attr('src', url);
                    });

                    jQuery.jQueryPopup.initClass("form", popupClass);
                }
            });
        },

        message: '',

        body: function (popupId) {
                return '<div id="popup_container_' + popupId + '" class="popupContainerClass">' +
                    '<div id="popup_message_' + popupId + '"></div>' +
                    '</div>' 
        }
        
    };



    // Shortuct functions
    jAlert = function (message, title, callback, buttonText) {
        jQuery.jQueryPopup.alert(message, title, callback, buttonText);
    };

    // value es el parametro pasado al callback
    jAlertValue = function (message, title, callback, value, buttonText) {
        jQuery.jQueryPopup.alertValue(message, title, callback, value, buttonText);
    };

    jConfirm = function (message, title, callback, yesButtonText, noButtonText) {
        return jQuery.jQueryPopup.confirm(message, title, callback, yesButtonText, noButtonText);
    };

    jPrompt = function (message, value, title, callback, yesButtonText, noButtonText) {
        jQuery.jQueryPopup.prompt(message, value, title, callback, yesButtonText, noButtonText);
    };

    jAsk = function (message, value, title, yesButtonText, noButtonText, callback) {
        jQuery.jQueryPopup.ask(message, value, title, yesButtonText, noButtonText, callback);
    };

    jUrlPopup = function (url, popupClass, title, buttons, onloadFunction, onCloseFunction, beforeCreateFunction, appendToDiv, id, isModal) {
        jQuery.jQueryPopup.urlPopup(url, popupClass, title, buttons, onloadFunction, onCloseFunction, beforeCreateFunction, appendToDiv, id, isModal);
    };

    jFormPopup = function (url, popupClass, title) {
        jQuery.jQueryPopup.formPopup(url, popupClass, title);
    };
})(jQuery);


// Añade la/s capa/s div que se desee a obj
function AddDivsToObj(obj, divs) {

    var layer = obj;

    if (obj != '') {
        var i = 0;
        var divsArr = divs.split(',');
        for (i = 0; i < divsArr.length; i++) {
            layer = "<div id='" + divsArr[i] + "'>" + layer + "</div>";
        }
    }

    return layer;
}
var MyAccountLoginForm;

(function ($) {
    'use strict';

 
    //Funcion que permite hacer login en MyAccount en un iframe
    MyAccountLoginForm = function (settings) {

        var iframeHeight = 648;

        if (settings.withoutRegisterMode)
            iframeHeight = 500;

        if (!settings.url)
            throw "settings.url must be specified";

        if (typeof settings.onSuccess !== 'function')
            throw "settings.onSuccess callback must be specified and be a function";

        if (settings.onCancel && typeof settings.onCancel !== 'function')
            throw "settings.onCancell callback must be specified and be a function";

        var options = $.extend({}, settings);
        var $head, $el, $iframe;
		
		//Funcion principal a llamar desde la fuente externa
        this.display = function () {
            var elements = createElements(options);

            $head = elements.$head;
            $el = elements.$el;
            $iframe = elements.$iframe;

            $head.appendTo(document.head);
            $el.appendTo(document.body);
            $('.iframeLogin').hide();
            this.attachEvents();
        };

        this.show = function () {
            $('.iframeLogin').show();
        };

        this.attachEvents = function () {

            var self = this;

            var height;

            $el.on('click', '[data-register-action=close-dialog]', function () { self.close(); });

            $(window).on("message", function (e) {

                var data = e.originalEvent.data;

                if (data.source === 'login') {

                    self.messageReceived(data);

                } else if (data.source === 'show') {
                    $('#back').hide();
                    document.getElementsByTagName("html")[0].style.overflow = "hidden";
                    $('.iframeLogin').show();


                } else if (data.source === 'large') {

                    height = $('#iframeContainer').height();
                    if (height < iframeHeight + 15)
                        $('#iframeContainer').height(height + 50);

                } else if (data.source === 'short') {

                    height = $('#iframeContainer').height();
                    if (height > iframeHeight)
                        $('#iframeContainer').height(height - 50);
                } else if (data.source === 'displayBack') {
                    document.getElementsByTagName("html")[0].style.overflow = "hidden";
                    $('#back').show();
                }
            });
        };

        this.detachEvents = function () {
            var self = this;
            $el.off('click');
            $(window).off("message");
        };

        this.close = function () {
            this.detachEvents();
            $el.remove();
            if (options.onCancel) {
                options.onCancel.call(this);
            }
            document.getElementsByTagName("html")[0].style.overflow = "auto";
        };

        this.closeWithoutCancel = function () {
            this.detachEvents();
            $el.remove();
            document.getElementsByTagName("html")[0].style.overflow = "auto";
        };

        this.messageReceived = function (data) {
            if (data.logged === true) {
                options.onSuccess.call(this, data);
                this.closeWithoutCancel();
            }
            else if (data.logged === false) {
                this.close();
            } 
        };

        return this;
    };


    function createElements(options) {

        var iframeHeight = 648;

        if (options.withoutRegisterMode)
            iframeHeight = 500;

        var $head = $('<link rel="stylesheet" href="https://cdn.wolterskluwer.io/wk/icons/1.2.x/wk-icons-all-open.min.css">');

        var $background = $("<div class='iframeLogin'>")
            .css({
                position: "fixed",
                top: 0,
                backgroundColor: "rgba(0,0,0,0.5)",
                bottom: 0,
                right: 0,
                left: 0,
                zIndex: 1031,
                fontSize: "14px"
            });
            

        var $container;

        if (!options.loginmodel) {

            $container = $("<div>")
                .css({
                    position: "absolute",
                    top: "20px",
                    bottom: "20px",
                    minHeight: "400px",
                    maxHeight: "663px",
                    width: "70%",
                    margin: "0 15%",
                    backgroundColor: "white",
                    border: "1px #555 solid",
                    paddingBottom: "28px",
                    overflowY: "hidden"
                    
                })
                .appendTo($background);
        } else {

            $container = $("<div id='iframeContainer' class='iframeLogin' style='border-radius: 10px; border: 4px solid rgb(0, 0, 0)'>")
                .css({
                    top: "20px",
                    bottom: "20px",
                    height: iframeHeight,
                    width: "510px",
                    "max-width": "95%",
                    margin: "0 auto",
                    display: "block",
                    backgroundColor: "white",
                    border: "1px #555 solid",
                    overflowY: "hidden"
                })
                .appendTo($background);
        }

        $("<div><a id='back' href='##' onClick='history.go(-1); return false;'><span style='float:left; text-decoration:none; color: #007ac3;' class='wk-icon-arrow-left' id='back' aria-hidden='true'/></a> <span data-register-action='close-dialog' style='cursor: pointer; color:#007ac3;' title='Close window'>X</span></div>")
            .css({
                textAlign: "right",
                fontFamily: "sans-serif",
                fontSize: "27px",
                marginRight: "10px",
                marginLeft: "10px",
                paddingRight: "8px",
                paddingTop: "8px"
            })
            .appendTo($container);

        var url = options.url
            + '?encryptedModel=' + btoa(JSON.stringify(options.data));



        var $iframe = $("<iframe class='iframeLogin' scrolling='yes' frameborder='0'>");
        $iframe
            .attr("src", url)
            .css({
                width: "100%",
                height: "95%"             
            })
            .appendTo($container);



        return {
            $head: $head,
            $el: $background,
            $iframe: $iframe
        };
    }

})(jQuery);
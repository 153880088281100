﻿// Objeto con la lógica para poder implementar el patrón observer
// Susbscribers: Lista de array de subscriptores. Si al subscribirse se pasa un tipo, se subscribe al
//      array de ese tipo (los tipos son como los "distintos eventos" a los que nos suscribimos) 
// subscribe(fn, type): Función para subscribirnos a un evento del tipo type (o al evento por defecto, si no se pasa
//      nada) con la función fn (función de respuesta al evento)
// unsubscribe(fn, type): Igual que subscribe pero para quitarse del evento
// publish(publication, type): Función para que se notifique a los subscriptores que se produjo el evento de tipo
//      type. "publication" es la información que se le pasa como argumento a la función de respuesta del evento
// visitSbuscribers(action, arg, type): Es una función interna, no se debería llamar

var publisher = {
    subscribers: {
        any: [] // event type: subscribers
    },
    subscribersInstance: {
        any: []
    },
    
    
    subscribe: function (fn, type) {
        type = type || 'any';
        if (typeof this.subscribers[type] === "undefined") {
            this.subscribers[type] = [];
        }
        if (this.subscribers[type].indexOf(fn) == -1) {
            this.subscribers[type].push(fn);
        }
    },
    subscribeInstance: function(fn, type, instance) {
        type = type || 'any';
        if (typeof this.subscribersInstance[type] === "undefined") {
            this.subscribersInstance[type] = [];
        }
        if (this.subscribersInstance[type].indexOf(fn) == -1) {
            var suscribeObject = new Object();
            suscribeObject.function = fn;
            suscribeObject.instance = instance;
            this.subscribersInstance[type].push(suscribeObject);
        }
    },
   
    unsubscribe: function (fn, type) {
        this.visitSubscribers('unsubscribe', fn, type);
    },
    publish: function (publication, type) {
        this.visitSubscribers('publish', publication, type);
    },

    publishInstance: function(publication, type)
    {
        this.visitSubscribersInstance('publish', publication, type);
    },
    visitSubscribers: function (action, arg, type) {
        var pubtype = type || 'any',
            subscribers = this.subscribers[pubtype],
            i,
            max = subscribers === undefined || subscribers == null  
                    ? 0 
                    : subscribers.length;

        for (i = 0; i < max; i += 1) {
            if (action === 'publish') {
                subscribers[i](arg);
            } else {
                if (subscribers[i] === arg) {
                    subscribers.splice(i, 1);
                }
            }
        }
    },

    visitSubscribersInstance: function (action, arg, type) {
        var pubtype = type || 'any',
            subscribersInstance = this.subscribersInstance[pubtype],
            i,
            max = subscribersInstance === undefined || subscribersInstance == null
                    ? 0
                    : subscribersInstance.length;

        for (i = 0; i < max; i += 1) {
            if (action === 'publish') {
                subscribersInstance[i].function.call(subscribersInstance[i].instance, arg);
            } else {
                if (subscribersInstance[i] === arg) {
                    subscribersInstance.splice(i, 1);
                }
            }
        }
    }

};

// Convierte cualquier objeto en un objeto observable
function makePublisher(o) {
    var i;
    for (i in publisher) {
        if (publisher.hasOwnProperty(i) && typeof publisher[i] === "function") {
            o[i] = publisher[i];
        }
    }
    o.subscribers = { any: [] };
};

function makePublisherInstance(o) {
    var i;
    for (i in publisher) {
        if (publisher.hasOwnProperty(i) && typeof publisher[i] === "function") {
            o[i] = publisher[i];
        }
    }
    o.subscribersInstance = { any: [] };
};
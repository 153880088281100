function namespace(namespaceString) {
    var parts = namespaceString.split('.'),
        parent = window,
        currentPart = '';

    for (var i = 0, length = parts.length; i < length; i++) {
        currentPart = parts[i];
        parent[currentPart] = parent[currentPart] || {};
        parent = parent[currentPart];
    }

    return parent;
}


function Control(useClassesInsteadOfIds) {}

function setDefault(divname, max) {}

function HideTooltip() {}

function ShowTooltip() {}

function Redirection(idd) {
    const documentlink = /^(\w{2}\d{10})?_(\d{8}|Vigente)?.HTML?(#\w*)?/i;
    const matchResult = idd.match(documentlink);

    const iddDoc = matchResult[1] || '';
    const version = matchResult[2] || '';
    const anchor = matchResult[3] || '';

    window.open('https://laleydigital.laleynext.es/Content/Documento.aspx?idd=' + iddDoc + '&version=' + version + '&anchor=' +  anchor);
}

function SaltoExt(url) {
    window.open(url);
}



function vImg(imgdoc) {
    var len = imgdoc.length - 4;
    var ruta = "https://medias.externalnaw.es/medias/ES/img";
    ruta += imgdoc.substr(len, 1) + "/" + imgdoc.substr(len + 1, 1) + "/" + imgdoc.substr(len + 2, 1) + "/" + imgdoc.substr(len + 3, 1) + "/" + imgdoc.toLowerCase();;
    var ImgDoc = ruta + ".jpg";
    var ImgSrc;
    var estiloActual;
    if (document.getElementById(imgdoc)) {
        ImgSrc = document.getElementById(imgdoc);
        ImgSrc.src = ImgDoc;
        estiloActual = ImgSrc.className;
        ImgSrc.className = (estiloActual == 'op') ? 'cl' : 'op';
    }
}

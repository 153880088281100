﻿jQuery(document).ready(function() {
    if (jQuery('.FO .rBox').length || jQuery('.FO .opt').length) {
        if (navigator.userAgent.toLowerCase().indexOf('firefox') > -1 || navigator.userAgent.match(/Trident\/7\./)) {
            jQuery("div.opt > input[type=checkbox]").after("<p class='FFox_IE'>Activa el check si deseas incluir este párrafo</p>");
        }
        var $radios = jQuery('.rBox input[type=radio]');
        $radios.change(function() {
            $radios.closest('.rBox').removeClass('sel').find('*').removeClass('sel');
            $radios.filter(':checked').closest('.rBox').addClass('sel');
        });
        var $check = jQuery('.opt input[type=checkbox]');
        $check.change(function() {
            $check.closest('.opt').removeClass('sel');
            $check.filter(':checked').closest('.opt').addClass('sel');
        });
        var $radSpan = jQuery('span.orSelect input[type=radio]');
        $radSpan.change(function() {
            $radSpan.closest('span.orSelect').removeClass('sel');
            $radSpan.filter(':checked').closest('span.orSelect').addClass('sel');
        });
        jQuery(".opt > a[title='Eliminar un campo del formulario']").parent().addClass('xsel');
        jQuery(".rSelect").prev('input').addClass('block');
    }
});

function cargaCheck() {
    if (jQuery('.FO .rBox').length || jQuery('.FO .opt').length) {
        if (navigator.userAgent.toLowerCase().indexOf('firefox') > -1 || navigator.userAgent.match(/Trident\/7\./)) {
            jQuery("div.opt > input[type=checkbox]").after("<p class='FFox_IE'>Activa el check si deseas incluir este párrafo</p>");
        }
        var $radios = jQuery('.rBox input[type=radio]');
        $radios.change(function() {
            $radios.closest('.rBox').removeClass('sel').find('*').removeClass('sel');
            $radios.filter(':checked').closest('.rBox').addClass('sel');
        });
        var $check = jQuery('.opt input[type=checkbox]');
        $check.change(function() {
            $check.closest('.opt').removeClass('sel');
            $check.filter(':checked').closest('.opt').addClass('sel');
        });
        var $radSpan = jQuery('span.orSelect input[type=radio]');
        $radSpan.change(function() {
            $radSpan.closest('span.orSelect').removeClass('sel');
            $radSpan.filter(':checked').closest('span.orSelect').addClass('sel');
        });
        jQuery(".opt > a[title='Eliminar un campo del formulario']").parent().addClass('xsel');
        jQuery(".rSelect").prev('input').addClass('block');
    }
}

function borrarLiteralesCheck() {
    if (jQuery('.FO .rBox').length || jQuery('.FO .opt').length) {
        if (navigator.userAgent.toLowerCase().indexOf('firefox') > -1 || navigator.userAgent.match(/Trident\/7\./)) {
            jQuery("div.opt > input[type=checkbox]").attr('value', '');
        }
        var $radios = jQuery('.rGroup .radiogroup-text');
        $radios.each(function(index, radio) {
            radio.hidden = false;
        });
        var $check = jQuery('.opt .checkbox-text');
        $check.each(function(index, check) {
            check.hidden = false;
        });

        var $check = jQuery('.opt .plusminusgroup-text');
        $check.each(function(index, check) {
            check.hidden = false;
        });
    }
}

var texts = {
    Close: 'cerrar',
    minFieldsIncludedMessage: 'Mínimo alcanzado',
    maxFieldsIncludedMessage: 'Máximo alcanzado',
    minFieldsIncludedTitle: 'ContractBox',
    maxFieldsIncludedTitle: 'ContractBox'
};

function getLanguageText(var1, var2) {
    return texts[var2];
}

//Hacemos que la pestaña del quantcast se oculte al aceptar pero se pueda ejecutar desde el enlace del footer:
jQuery(document).ready(function() {
	if (typeof loadQuantcast === 'function') {
		loadQuantcast();
	}	
});

function openQuantcast() {
    var evt = new MouseEvent('click', {
        bubbles: true,
        cancelable: true,
        view: window
    });
    document.querySelector('.qc-cmp2-persistent-link').dispatchEvent(evt);
}

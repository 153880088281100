// START Campaña publicitaria


var ssaUrl = 'https://' + 'pixel.sitescout.com/iap/ac75398365206ea6';
new Image().src = ssaUrl;
(function(d) {
    var syncUrl = 'https://' + 'pixel.sitescout.com/dmp/asyncPixelSync';
    var iframe = d.createElement('iframe');
    (iframe.frameElement || iframe).style.cssText = "width: 0; height: 0; border: 0; display: none;";
    iframe.src = "javascript:false";
    d.body.appendChild(iframe);
    var doc = iframe.contentWindow.document;
    doc.open().write('<body onload="window.location.href=\'' + syncUrl + '\'">');
    doc.close();
})(document);


// END Campaña publicitaria